import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/layout';
import NoPage from "./components/nopage/nopage";
import './App.css';
import Homepage from "./components/homepage/homepage";
import ChangelogPage from "./components/changelog/changelogPage";
import CapshipPrice from "./components/calculators/capships/capship_price";
import ShipCalc from "./components/calculators/ship/shipCalc";
import {
    armor_optimisation_url_part,
    capship_calc_url_part, changelog_url_part,
    ship_calc_url_part,
    thruster_optimisation_url_part,
    values_url_part
} from "./components/global_values";
import ThrusterOptimisation from "./components/calculators/thruster_optimisation/thruster_optimisation";
import Values from "./components/values/values";
import './dark_mode.css'
import ArmorOptimisation from "./components/calculators/armor_optimisation/armor_optimisation";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Homepage/>}/>
                    <Route path={ship_calc_url_part} element={<ShipCalc/>}/>
                    <Route path={changelog_url_part} element={<ChangelogPage/>}/>
                    <Route path={capship_calc_url_part} element={<CapshipPrice/>}/>
                    <Route path={thruster_optimisation_url_part} element={<ThrusterOptimisation/>}/>
                    <Route path={values_url_part} element={<Values/>}/>
                    <Route path={armor_optimisation_url_part} element={<ArmorOptimisation/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
